<template>
    <div>
        <v-row>
            <v-col>
                <v-switch
        v-model="info"
        label="Info"
        inset
        color="var(--primary-color)"
        @change="updateInfo($event)"
        ref="info"
        ></v-switch>
        <v-switch
        v-model="actualites_locales"
        label="Actualités locales"
        inset
        color="var(--primary-color)"
        @change="updateActuLocales($event)"
        ref="actualites_locales"
        ></v-switch>
        <v-switch
        v-model="environnement"
        label="Environnement"
        inset
        color="var(--primary-color)"
        @change="updateEnvironnement($event)"
        ref="environnement"
        ></v-switch>
        <v-switch
        v-model="sciences_techno"
        label="Sciences et Techno"
        inset
        color="var(--primary-color)"
        @change="updateSciences($event)"
        ref="sciences_techno"
        ></v-switch>
        <v-switch
        v-model="culture_musique"
        label="Culture et Musique"
        inset
        color="var(--primary-color)"
        @change="updateCultureMusique($event)"
        ref="culture_musique"
        ></v-switch>
        <v-switch
        v-model="sante"
        label="Santé et Bien-être"
        inset
        color="var(--primary-color)"
        @change="updateSante($event)"
        ref="sante"
        ></v-switch>
                <v-switch
        v-model="vie_pratique"
        label="Vie pratique"
        inset
        color="var(--primary-color)"
        @change="updateViePratique($event)"
        ref="vie_pratique"
        ></v-switch>
        <v-switch
        v-model="sport"
        label="Sport"
        inset
        color="var(--primary-color)"
        @change="updateSport($event)"
        ref="sport"
        ></v-switch>
       
            </v-col>
        </v-row>
        
        
    </div>
</template>

<script>

import { getAPI } from "@/axios";
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        info: null,   
        actualites_locales: null,   
        environnement: null,   
        sciences_techno: null,   
        culture_musique: null,   
        sante: null,   
        vie_pratique: null,
        sport: null,
    }),
    props: {
        update:String,
    },
    created: function(){
        var settings = this.getSettings
        if (settings["filtering"]){

            this.info = settings["filtering"]["Info"];
            this.actualites_locales = settings["filtering"]["Actualités locales"];
            this.environnement = settings["filtering"]["Environnement"];
            this.sciences_techno = settings["filtering"]["Sciences et Techno"];
            this.culture_musique = settings["filtering"]["Culture & Musique"];
            this.sante = settings["filtering"]["Santé & Bien-être"];
            this.vie_pratique = settings["filtering"]["Vie pratique"];
            this.sport = settings["filtering"]["Sport"];
        }
    },
    computed: {
       ...mapGetters([
            'getArticles',
            'getSettings'
        ]),
        
    },
    methods: {
        ...mapActions([
            'setListArticles',
            'setSettings'
        ]),

        updateInfo(event){
            this.setNewSettings("Info", event);
        },

        updateActuLocales(event){
            this.setNewSettings("Actualités locales", event);
        },

        updateEnvironnement(event){
            this.setNewSettings("Environnement", event);
        },

        updateSciences(event){
            this.setNewSettings("Sciences et Techno", event);
        },

        updateCultureMusique(event){
            this.setNewSettings("Culture & Musique", event);
        },

        updateSante(event){
            this.setNewSettings("Santé & Bien-être", event);
        },

        updateViePratique(event){
            this.setNewSettings("Vie pratique", event);
        },

        updateSport(event){
            this.setNewSettings("Sport", event);
        },

        setNewSettings(setting, value){
            if(value != undefined){
                                
                 //Update settings stored
                
                var settings = this.getSettings;
                settings["filtering"][setting] = value;
                this.setSettings(settings);
                
                //Update articles visibility
                var article_list = this.getArticles;
                for (let element of article_list){
                    if (setting == element.article.category)
                        element.visibility = value;
                }
                this.setListArticles(article_list);
                
                getAPI.post("update_settings", {
                params: {
                    setting_group: "filtering",
                    setting: setting,
                    value: value
                }
                })
                .then()
                .catch(err => {
                    console.log(err);
                });

            }
        }
    },
    watch: {
        // classic21: function (val) {
        //     this.setNewSettings("classic21", val);
            
        // },
        // musiq3: function (val) {
        //     this.setNewSettings("musiq3", val);
        // },
        // radio: function (val) {
        //     this.setNewSettings("radio", val);
        // },
        // tendance: function (val) {
        //     this.setNewSettings("tendance", val);
        // },
        // tv: function (val) {
        //     this.setNewSettings("tv", val);
        // },
        // culture: function (val) {
        //     this.setNewSettings("culture", val);
        // },
        // info: function (val) {
        //     this.setNewSettings("info", val);
        // },
        // sport: function (val) {
        //     this.setNewSettings("sport", val);
        // },
        // lapremiere: function (val) {
        //     this.setNewSettings("lapremiere", val);
        // },
        // vivacite: function (val) {
        //     this.setNewSettings("vivacite", val);
        // },
        // tipik: function (val) {
        //     this.setNewSettings("tipik", val);
        // },
        update: function(val){
            for (const key of Object.keys(this.$data)) {
                if (key == val){
                    const filter = this.$refs[key]
                    filter.onChange();
                }
                // else {
                //     const filter = this.$refs[key] 
                //     if (filter.$options.propsData.inputValue == true){
                //         console.log("test")
                //         filter.onChange();
                //     }
                    
                // }
                
            }
            

            
        }

        
        
            
    }
}
</script>