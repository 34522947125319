var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn-toggle',{staticClass:"d-flex flex-wrap tooltip expand",attrs:{"tile":"","mandatory":"","data-title":"Hypertext Markup Language"},on:{"change":function($event){return _vm.updateSettings($event)}},model:{value:(_vm.toggled_profile),callback:function ($$v) {_vm.toggled_profile=$$v},expression:"toggled_profile"}},[_c('v-btn',{attrs:{"outlined":"","active-class":"btn-active"}},[(this.isToggled(0))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment-bookmark-outline")])]}}],null,false,1382960801)},[_vm._v(" Actualité ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment-bookmark-outline")])]}}])},[_vm._v(" Actualité ")])],1),_c('v-btn',{attrs:{"outlined":"","active-class":"btn-active"}},[(this.isToggled(1))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-soccer")])]}}],null,false,3523920869)},[_vm._v(" Sport ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-soccer")])]}}])},[_vm._v(" Sport ")])],1),_c('v-btn',{attrs:{"outlined":"","active-class":"btn-active"}},[(this.isToggled(2))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-palette")])]}}],null,false,954833779)},[_vm._v(" Art et Culture ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-palette")])]}}])},[_vm._v(" Art et Culture ")])],1),_c('v-btn',{attrs:{"outlined":"","active-class":"btn-active"}},[(this.isToggled(3))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-home")])]}}],null,false,1207810529)},[_vm._v(" Vie pratique ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v("mdi-home")])]}}])},[_vm._v(" Vie pratique ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }