<template>
<div>
    <v-btn-toggle tile mandatory class="d-flex flex-wrap tooltip expand" data-title="Hypertext Markup Language" v-model="toggled_profile" @change="updateSettings($event)">
        <v-btn outlined active-class="btn-active">
          <v-tooltip bottom v-if="this.isToggled(0)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color=white v-bind="attrs" v-on="on" >mdi-comment-bookmark-outline</v-icon>
            </template>
            Actualité
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color='grey' v-bind="attrs" v-on="on">mdi-comment-bookmark-outline</v-icon>
            </template>
            Actualité
          </v-tooltip>
        </v-btn>
 
        <v-btn outlined active-class="btn-active">
          <v-tooltip bottom v-if="this.isToggled(1)">
            <template v-slot:activator="{ on, attrs }">
            <v-icon  color=white v-bind="attrs" v-on="on" >mdi-soccer</v-icon>
            </template>
            Sport
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
            <v-icon  color='grey' v-bind="attrs" v-on="on">mdi-soccer</v-icon>
            </template>
            Sport
          </v-tooltip>
        </v-btn>


        <v-btn outlined active-class="btn-active">
          <v-tooltip bottom v-if="this.isToggled(2)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color=white v-bind="attrs" v-on="on">mdi-palette</v-icon>
            </template>
            Art et Culture
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">    
              <v-icon color='grey' v-bind="attrs" v-on="on">mdi-palette</v-icon>
            </template>
            Art et Culture
          </v-tooltip>
        </v-btn>

        <v-btn outlined active-class="btn-active">
          <v-tooltip bottom v-if="this.isToggled(3)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color=white v-bind="attrs" v-on="on">mdi-home</v-icon>
            </template>
            Vie pratique
          </v-tooltip>
          
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon  color='grey' v-bind="attrs" v-on="on">mdi-home</v-icon>
            </template>
            Vie pratique
          </v-tooltip>
        </v-btn>
    </v-btn-toggle>
</div>
</template>

<script>
import { getAPI } from "@/axios";
import { mapActions, mapGetters } from 'vuex';


export default {

    data: () => ({
      toggled_profile:0,
      profiles_mapping: {
        "news": 0,
        "sport":1,
        "science-culture":2,
        "lifestyle": 3
      },
      profiles_list: ["news", "sport", "science-culture", "lifestyle"]

    }),
    computed: {
       ...mapGetters([
            'getSettings',

        ]),
        
    },
    methods: {
      ...mapActions([
            'setSettings',
            'setHistory',
            'setRefreshDisabled'
        ]),
      loadSettings(){
          var settings = this.getSettings
          this.toggled_profile = this.profiles_mapping[settings["history"]["profile"]]
      },
      updateSettings(event){
        this.setRefreshDisabled(false)
        var settings = this.getSettings;
        settings["history"]["profile"] = this.profiles_list[event]
        this.setSettings(settings);

        getAPI.post("update_settings", {
          params: {
              setting_group: "history",
              setting: "profile",
              value: this.profiles_list[event]
          }
          })
          .then()
          .catch(err => {
              console.log(err);
        });

        getAPI.post("get_profile_history",{
                params: this.profiles_list[event]
            })
          .then( response => {
            var readings = response.data.articles;
            var readings_with_visibility= [];
            for(let reading of readings){
              let element = new Object();
              element["article"] = reading;
              element["visibility"] = true;
              readings_with_visibility.push(element);
            }
            this.setHistory(readings_with_visibility);
            
          })
          .catch(err => {
              console.log(err);
        });
      },
            
      isToggled(index){
            if (this.toggled_profile == index) {
                return true
            }
            else {
                return false
            }
        },
    },

    created: function(){
        this.loadSettings();
    },

}
</script>

<style scoped>
.v-btn-toggle >>> .btn-active{
    background-color: var(--primary-color)!important;

}

.v-btn {
      border: solid 1px !important;

}



</style>