<template>
    <div id="settings-menu">
        <!-- <v-card elevation="1" class="mb-4">
            <autocomplete class="pt-4 pl-4 pr-4" id="tour-home-step-search"></autocomplete>
        </v-card> -->
        <!-- <v-card id="tour-home-step-comparison" class="mb-4 mt-4" elevation="1">
                <v-switch color="var(--primary-color)"  class="pt-4 pl-2 pr-2" label="Activer le panel de comparaison" :value="getMonitoringStatus" @change="this.updateMonitoringStatus"></v-switch>
        </v-card > -->
        <v-expansion-panels  multiple :value="expanded">
            
            <v-expansion-panel>
                <v-expansion-panel-header id="tour-home-step-recommender">
                    <v-row no-gutters>
                        Contrôle du fil d'actualité
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.algorithm"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider class="mb-4"></v-divider>
                    <v-row no-gutters class="mb-4">
                        Choix du profil
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.profile"></tooltip>
                    </v-row>
                    <user-profile-selection v-if="this.getSettingsStatus"></user-profile-selection>
                    <v-divider class="mt-4 mb-4"></v-divider>
                    <v-row no-gutters class="mb-4">
                        Configuration de l'algorithme
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.process"></tooltip>
                    </v-row>
                    <recommender v-if="this.getSettingsStatus"></recommender>
                </v-expansion-panel-content>
            </v-expansion-panel>

            
            <v-expansion-panel>
                <v-expansion-panel-header id="tour-home-step-filtering">
                    <v-row no-gutters>
                        Filtrage du fil d'actualités
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.filtering"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <filtering :update="this.getFilteringUpdate" v-if="this.getSettingsStatus"></filtering>
                </v-expansion-panel-content>
            </v-expansion-panel>
            

            
        </v-expansion-panels>
    </div>
</template>

<script>
import Filtering from '../components/Filtering.vue';
import Tooltip from '../components/Tooltip.vue';
import Recommender from '../components/Recommender.vue';
// import Autocomplete from '../components/Autocomplete.vue';
import UserProfileSelection from '../components/UserProfileSelection.vue';
import { mapActions, mapGetters } from 'vuex'


export default {
    components:{
        Tooltip,
        Recommender,
        // Autocomplete,
        UserProfileSelection,
        Filtering
    },
    data: () => ({
        expanded:[0,1],
        tooltips:{
            filtering:"Décocher une catégorie fera disparaître du flux central \nles articles correspondants.",
            algorithm:"Ajuster ces paramètres modifiera le comportement de\nl'algorithme qui décide du classement des articles.\nAprès chaque manipulation, il faut appuyer sur \nle bouton \"actualiser\" pour générer un nouveau \nfil d'actualités qui respecte ces nouveaux paramètres.",
            profile:"En fonction du profil que tu vas sélectionner, l'algorithme va \nte faire des recommandations différentes. Tu peux\nincarner un lecteur d'actualités quotidiennes,\nun fan de sport, un passionné de culture ou \nun amateur d'articles santé et bien être.",
            process:"Plusieurs variables influencent le calcul de la \npertinence d'un article en fonction de ton profil."
        }
    }),

    methods:{
        ...mapActions([
        'setMonitoringStatus',
        ]),
        updateMonitoringStatus(){
            let status = this.getMonitoringStatus
            console.log(status)
            
            this.setMonitoringStatus(!status)
        }
    },
    computed: {
       ...mapGetters([
            'getMonitoringStatus',
            'getSearchStatus',
            'getSettingsStatus',
            'getMaxArticlesDisplayed',
            'getArticles',
            'getFilteringUpdate'
        ]),
        
        
        
    },
    watch: {
         getSearchStatus: function () {
            var container = document.getElementById("settings-menu");
            container.scrollIntoView({behavior:"smooth",block:"start"});
         },

    },
    created: function(){
        this.setMonitoringStatus(false)
    }
    
    
}
</script>


<style scoped>
    .v-subheader{
        font-size:20px;
    }
    .settings-menu{
        scroll-margin-top :7rem;
    }

</style>