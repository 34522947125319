<template>
  <v-app>
      <router-view ></router-view>
  </v-app>
</template>

<script>
import { getAPI } from "@/axios";
import { mapActions} from 'vuex'


export default {
  name: 'App',

  components: {
  },

  data: () => ({
    is_inactive: true,
    user_activity_timeout: null,
    inactive_user_time_threshold: 61000,
    user_activity_throttler_timeout : null,
    user_activity_throttler_time:500,
    user_activity_ping_interval:null,
    ping_interval:60000
  }),

  methods:{
      ...mapActions([
        'setChartColors'
      ]),
      resetUserActivityTimeout(){
        clearTimeout(this.user_activity_timeout);
        if (this.is_inactive == true){
          this.postLog("active")
          if (!this.user_activity_ping_interval){
            this.user_activity_ping_interval = setInterval( () => {
             this.postLog("active")
            }, this.ping_interval)
          }          

        }
        this.is_inactive=false
        this.user_activity_timeout = setTimeout( () => {
          this.inactivityDetected();
        }, this.inactive_user_time_threshold)
      },
      inactivityDetected(){
        this.is_inactive=true
        clearInterval(this.user_activity_ping_interval)
        this.user_activity_ping_interval = undefined;
        this.postLog("inactive")
      },

      activateActivityTracker(){
        window.addEventListener('mousemove', this.userActivityThrottler);
        window.addEventListener('scroll', this.userActivityThrottler);
        window.addEventListener('keydown', this.userActivityThrottler);
        window.addEventListener('touchmove', this.userActivityThrottler);
        window.addEventListener('touchstart', this.userActivityThrottler);
        window.addEventListener('beforeunload',this.logUserLeaving)
      },
      deactivateActivityTracker(){
        window.removeEventListener('mousemove', this.userActivityThrottler);
        window.removeEventListener('scroll', this.userActivityThrottler);
        window.removeEventListener('keydown', this.userActivityThrottler);
        window.removeEventListener('touchmove', this.userActivityThrottler);
        window.removeEventListener('touchstart', this.userActivityThrottler);
      },

      userActivityThrottler(){
        if (!this.user_activity_throttler_timeout) {
          this.user_activity_throttler_timeout = setTimeout ( () =>{
            this.resetUserActivityTimeout()
            clearTimeout(this.user_activity_throttler_timeout)
            this.user_activity_throttler_timeout = null;
          }, this.user_activity_throttler_time)
        }
      },

      postLog(status){
        let status_binary = null
        if (status == "active"){
          status_binary = 1
        }
        else {
          status_binary = 0
        }
        getAPI.post("post_log_activity", {
                params: status_binary
            })
            .then()
            .catch(err => {
                console.log(err);
            }); 
      },
      logUserLeaving(){
        this.postLog("inactive")
      },


  },

  mounted() {
    document.title = "Alveho"
    this.activateActivityTracker()
    

    //Fetch the colors from global.css
    var style = getComputedStyle(document.body);
    let chart_colors = {};
    chart_colors["Info"] = style.getPropertyValue('--color-info')
    chart_colors["Actualités locales"] = style.getPropertyValue('--color-actualites-locales')
    chart_colors["Environnement"] = style.getPropertyValue('--color-environnement')
    chart_colors["Sciences et Techno"] = style.getPropertyValue('--color-sciences-techno')
    chart_colors["Culture & Musique"] = style.getPropertyValue('--color-culture-musique')
    chart_colors["Santé & Bien-être"] = style.getPropertyValue('--color-sante')
    chart_colors["Vie pratique"] = style.getPropertyValue('--color-vie-pratique')
    chart_colors["Sport"] = style.getPropertyValue('--color-sport')
    this.setChartColors(chart_colors)
  },
  destroyed() {
    this.deactivateActivityTracker()
    //this.postLog("inactive")
  }

};
</script>

<style>
@font-face {
  font-family: "Exo2";
  src: local("Exo2"),url(assets/fonts/Exo2-Thin.otf);
}

#app{
    background-image: url("assets/custom-background.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size:cover;
}

a:link {
  text-decoration: none;
  color:var(--contrast-color)!important;
}
a:visited {
  color: var(--primary-color)!important;
}
a:hover {
  color: var(--contrast-color)!important;
}
a:active {
  color:var(--contrast-color)!important;
}


</style>
