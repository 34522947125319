<template>
  <div class="wrapper">
    <navbar current_page="Admin"></navbar>
    <v-container fluid>
      <v-row align="center" justify="center" class="mt-15">
        <v-col>
          <v-card min-height="82vh" class="mr-10 ml-10">
            <v-icon size="100" color="teal" class="icon">mdi-account-edit</v-icon>
            <div class="text-subtitle-1 text-center">Enregistrement</div>
            <v-spacer></v-spacer>
            <registration-group></registration-group>
          </v-card>
        </v-col>
        <!-- <v-col>
          <v-card min-height="82vh" >
            <v-icon size="100" color="teal" class="icon">mdi-lock</v-icon>
              <div class="text-subtitle-1 text-center">Accès</div>
            <access></access>
          </v-card>
        </v-col>  -->
        <v-col> 
          <v-card min-height="82vh" class="mr-10 ml-10">
            <v-icon size="100" color="teal" class="icon">mdi-database-export</v-icon>
            <div class="text-subtitle-1 text-center">Export</div>
            <export></export>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
          
  </div> 
</template>

<script>
import Navbar from '../components/Navbar.vue';
import RegistrationGroup from '../components/RegistrationGroup.vue';
// import Access from '../components/Access.vue';
import Export from '../components/Export.vue';

  export default ({
    components: {
      Navbar,
      RegistrationGroup,
      // Access,
      Export
    }  
  })
</script>

<style scoped>

/* .container{
  margin-top: 4%;
  margin-left:2%;
  margin-right:2%;
  float:left;
  width:29%;
  border: solid rgb(230, 230, 230);
  border-width: 1px;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px rgb(150, 150, 150);
  min-height: 50vh;
} */


.icon{
  display:flex;
  justify-content:center;
}
</style>
