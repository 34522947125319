<script>
import {Bar, mixins} from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';


export default {
    extends: Bar,
    mixins: [mixins.reactiveProp],
            
    data() {
        return{
            
            articles: null,
            chartOptions: {
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero:true,
				}
			}],
		},
                plugins:{
                    datalabels:{
                        display:'auto',
                        color:'white',
                    },
                },
                responsive:true,
                maintainAspectRatio: false,
                legend:{
                    display:false,
                },
            }
        }
        
    },


    mounted(){
        var emptyMessage ={
            id: "my-plugin",
            afterDraw: function(chart) {
                if (chart.data.datasets[0].data.every(item => item === 0)) {
                    let ctx = chart.chart.ctx;
                    let width = chart.chart.width;
                    let height = chart.chart.height;

                    chart.clear();
                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font="25px Exo2";
                    ctx.fillText('Aucune donnée à afficher', width / 2, height / 2);
                    ctx.restore();
                }
            }
        };
        this.addPlugin(emptyMessage);
        this.addPlugin(ChartDataLabels);
        this.renderChart(this.chartData, this.chartOptions);
    },

    // watch: {
    //     getArticles: function (val) {
    //         this.articles = val;
    //         this.renderPieChart();
    //     },
    // }
}

</script>

<style>

</style>
