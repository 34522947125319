import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './routes';
import VueResource from 'vue-resource'
import vuetify from './plugins/vuetify';
import VueTour from 'vue-tour'
import "./assets/global.css"

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.use(VueResource)

Vue.http.options.credentials = true
Vue.http.options.crossOrigin = true
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.http.headers.common['Accept'] = 'application/json, text/plain, */*'
Vue.http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin'

Vue.config.productionTip = false



new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')