<template>
    <div>
        <v-expansion-panels inset multiple :value="expanded">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        Répartition des articles du flux non recommandé
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.barchart"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                            <bar-chart-container id="tour-home-step-barchart" v-if="this.getArticlesStatus" :articles="this.filtered_articles"  :parent="component_name"></bar-chart-container>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header id="tour-home-step-wordcloud">
                    <v-row no-gutters>
                        Nuage de mots du flux non recommandé
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.wordcloud"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class = "wordcloud">
                    <word-cloud :articles="this.filtered_articles" v-if="this.getArticlesStatus"></word-cloud>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels> 
    </div>
</template>
<script>
import WordCloud from '../components/WordCloud.vue';
import Tooltip from '../components/Tooltip.vue';
import BarChartContainer from './BarChartContainer.vue';
import { mapGetters } from 'vuex'

export default {
    components: {
    WordCloud,
    Tooltip,
    BarChartContainer
    },
    props: {
        articles_loaded: Boolean
    },
    data() {
        return {
            tooltips:{
                barchart:"Ce graphique représente la proportion d'articles présents dans \nton flux central en fonction de leurs catégories si les articles\nétaient classés par date de publication.",
                wordcloud:"Ce nuage de mot est l'équivalent de l'autre nuage\nsi les articles étaient classés par date de publication"
            },
            expanded: [0,1],
            component_name:"dataPanel"
        }
    },
    computed: {
       ...mapGetters([
            'getArticlesUnordered',
            'getMaxArticlesDisplayed',
            'getMonitoringStatus',
            'getArticlesStatus'
        ]),
        filtered_articles(){
            let processed_articles = []
            let count = 0
            const max_articles = this.getMaxArticlesDisplayed
            for (let element of this.getArticlesUnordered) {
                if (count >= max_articles) {
                    break
                }
                else if(element.visibility){
                    processed_articles.push(element)
                    count ++                    
                }
            }
            return processed_articles
        },
    },
}

</script>

<style scoped>
    .v-subheader{
        font-size:20px;
    }

    .v-expansion-panel >>> .v-expansion-panel-header{
        background-color:var(--contrast-color)!important;
        color:white;
        border-radius:1px!important;
    }

    .v-expansion-panel >>> .v-icon{
        color:white!important;
    }

    .v-expansion-panel{
        border:solid var(--contrast-color) 1px;
    }

</style>
