<template>
    <div>
        <v-expansion-panels inset multiple :value="expanded">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        Ton profil de lecture
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.barchart_profile"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <bar-chart-container :articles="this.getHistory" :parent="component_name"></bar-chart-container>
                </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        Ton flux d'actualités
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.barchart"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <bar-chart-container id="tour-home-step-piechart" :articles="this.filtered_articles" :parent="component_name"></bar-chart-container>
                </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
                <v-expansion-panel-header >
                    <v-row no-gutters>
                        Nuage de mots
                        <v-spacer></v-spacer>
                        <tooltip :text="tooltips.wordcloud"></tooltip>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class = "wordcloud">
                    <word-cloud :articles="this.filtered_articles" v-if="this.getArticlesStatus"></word-cloud>
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels> 
    </div>
</template>
<script>
import WordCloud from '../components/WordCloud.vue';
import Tooltip from '../components/Tooltip.vue';
import BarChartContainer from './BarChartContainer.vue';
import { mapGetters } from 'vuex'

export default {
    components: {
    WordCloud,
    Tooltip,
    BarChartContainer,
    },

    data() {
        return {
            tooltips:{
                barchart:"Ce graphique représente la proportion d'articles présents dans \nton flux central en fonction de leurs catégories.",
                barchart_profile:"Ce graphique décrit l'historique de navigation du profil utilisateur que tu as sélectionné. \nCet historique va influencer les choix de l'algorithme.",
                wordcloud:"Plus un mot sera grand, plus il apparaîtra souvent\n dans les articles de ton flux central."
            },
            expanded: [0,1,2],
            component_name:"dataPanel"
        }
    },
    computed: {
       ...mapGetters([
            'getArticles',
            'getArticlesStatus',
            'getMaxArticlesDisplayed',
            'getSettingsStatus',
            'getHistory'
        ]),
        filtered_articles(){
            let processed_articles = []
            let count = 0
            const max_articles = this.getMaxArticlesDisplayed
            for (let element of this.getArticles) {
                if (count >= max_articles) {
                    break
                }
                else if(element.visibility){
                    processed_articles.push(element)
                    count ++                    
                }
            }
            return processed_articles
        },
    },
}

</script>

<style scoped>


</style>
