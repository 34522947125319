<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class = "ml-5 mr-5"
    >
        <v-text-field
        v-model="group"
        :counter="15"
        :rules="[rules.required, rules.size, rules.whitespace]"
        label="Groupe"
        required
        ></v-text-field>

        <v-text-field
        v-model="number"
        :rules="[rules.required, rules.number]"
        label="Nombre"
        type="number"
        required
        ></v-text-field>

        <v-btn
        :disabled="!valid"
        color=var(--primary-color)
        class="mr-4 mb-4"
        outlined
        @click="validate"
        >
        Valider
        </v-btn>

        <v-btn
        color=var(--contrast-color)
        @click="reset"
        outlined
        class="mb-4"
        >
        Réinitialiser
        </v-btn>
        <alert-box :message="this.error" v-if="groupExists === true" class="mb-4"></alert-box>

    </v-form>
</template>


<script>
import { getAPI } from "@/axios";
import AlertBox from '../components/AlertBox.vue';

    export default {
        components: {
            AlertBox
        },

        data: () => ({
            groupExists: false,
            error:null,
            valid: true,
            group: '',
            rules: {
                required: v => !!v || 'Ce champ est nécessaire',
                size: v => (v && v.length <= 10) || 'Ce nom doit faire moins de 10 caractères',
                whitespace: v => /^\S+$/.test(v) || 'Le nom ne peut pas contenir d\'espaces blancs',
                number: v => /^[0-9]{1,2}$/.test(v) && v!=0 || 'Veuilez encoder un nombre entre 1 et 99',
        
            },
            number: '',

        }),

        methods:{
            validate () {
                this.$refs.form.validate();
                this.groupExists = false;
                this.post_users();
            },
            reset () {
                this.$refs.form.reset()
            },

            download(usersList){
                /*let data = JSON.parse(JSON.stringify(response.data));*/
                var fileURL = window.URL.createObjectURL(new Blob([usersList]));
                var fileLink = document.createElement('a');
                
                fileLink.href = fileURL;
                fileLink.setAttribute('download','users-list.txt');
                document.body.appendChild(fileLink);

                fileLink.click();

            },
            post_users: function(){
                if(this.number != '' && this.number!=null){
                    if(this.group != '' && this.group!=null){
                        getAPI.post("group_registration", {
                            params: {
                                group: this.group,
                                number: this.number,
                            }
                        })
                        .then(response => {
                            var usersList = response.data;
                            console.log(usersList)
                            this.download(usersList);
                            this.groupExists = false
                        }).catch(err => {
                            if (err.response.status == 422)
                            this.error = "Ce groupe existe déjà"
                            this.groupExists = true
                        });
                    }
                }
            },
            
        }
    }
</script>

<style scoped>
  .validation-btn{
    float: right;
  }
  .error{
    width: 70%;
    margin: 8% auto;
  }
  .logo{
    display: block;
    margin: auto;
    width: 25%;
    height: auto;
  }
  .registration-form{
    margin: auto;
    width: 70%;
  }
  .wrapper{
    margin: auto;
  }
</style>