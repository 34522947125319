<template>
    <v-tooltip top class="overEverything">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color=var(--contrast-color)>mdi-help-circle-outline</v-icon>
        </template>
        <p class="mb-0 pre-formatted">{{text}}</p>
    </v-tooltip>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style scoped>
    .pre-formatted{
        white-space:pre;
    }
    .overEverything{
        z-index:3000;
    }

</style>
