<template>
        <vue-word-cloud
        style="
            height: 250px;
        "
        :words=arrayWords
        :color="([, weight]) => weight > 0.5 ? '#ED5800' : weight > 0.2 ? '#008080' : '#739e9e'"
        font-family="Roboto"
        >
        <template v-slot="props">
            <v-tooltip>
            <template v-slot:activator="{on}">
                <div
                    style="cursor: pointer;"
                    v-on="on"
                    @click="onWordClick(props.word)"
                >{{props.text}}</div>
            </template>
            </v-tooltip>
        </template>
        </vue-word-cloud>
</template>

<script>
import VueWordCloud from 'vuewordcloud';
import { getAPI } from "@/axios";
import { mapActions } from 'vuex';


export default {
    components: {
        [VueWordCloud.name]: VueWordCloud,
    },
    data() {
        return {
            arrayWords: [],
            keywords_loaded:false,
        }
    },

    props: {
        articles: Array,
    },
    
    computed: {


        idArray(){
            var id_array = [];
            var count = 0;
            for(let element of this.articles){
                if (element.visibility){
                    id_array.push(element.article.id);
                }
                count ++;
                if (count >= 20 ) {
                    break
                }
            }
            return id_array;
        },
        
    },   
    methods: {
        ...mapActions([
            'setWordCloudSearch',
        ]),
        
        
        getKeywords(){
            getAPI.post("keywords", {
                params: {
                    ids: this.idArray,
                },
                }, { timeout: 50000 })
                .then(response => {
                    let results = JSON.parse(JSON.stringify(response));
                    this.arrayWords = results.data;
                    this.keywords_loaded = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        onWordClick(word){
            this.setWordCloudSearch(word[0])
            

        }
        
    },

    created: function () {
        this.getKeywords();
    },

    watch: {
         idArray: function () {
             this.getKeywords();
         },

    }
}

</script>

<style scoped>

</style>