<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        scrollable
        width="1200px"
        persistent
        >
          <v-card>
              <v-card-title>
                  <span class="headline">{{getCurrentArticle.title}}</span>
              </v-card-title>
              <v-card-text>
                  <div  class="container-content" v-for="(block, index) in getCurrentArticle.content" v-bind:key="index">
                      <div v-if="block.type === 'IMAGES'">
                          <img :src="block.content" />
                      </div>
                      <div v-else-if="block.type === 'MEDIA'">
                          
                      </div>
                      <div v-else v-html="block.content">
                          {{block.content}}
                      </div>
                  </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary darken-1"
                      text
                      @click="closeDialog()"
                  >
                      Fermer
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

  export default {
    data: () => ({
        dialog: false,
        currentContent: {}
    }),
    created: function () {
        this.dialog = this.getOpenDialogArticle;
        console.log(this.getCurrentArticle);
    },
    computed: {
       ...mapGetters([
            'getOpenDialogArticle',
            'getCurrentArticle'
        ])
    },
    methods:{
      ...mapActions([
        'setOpenDialogArticle',
      ]),
      closeDialog(){
          this.dialog = false;
          this.setOpenDialogArticle(false);
      },

    },
    watch: {
        getOpenDialogArticle: function (val) {
            this.dialog = val;
        },
    }
  }
</script>

<style scoped>
.container-content{
  width: fit-content;
  margin: auto;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #008080;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #008080;
}
::-webkit-scrollbar-thumb:active {
  background: #008080;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>