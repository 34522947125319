<template>
    <div>
      <v-app-bar
      style="border-bottom: 1px solid #d2d2d2 !important;  "
      flat
      color="white"
      src="../assets/background-nav.png"
      >
        <p class="name" >ALVEHO</p>
      </v-app-bar>
      <div class="container">
        <agreement-text></agreement-text>
        <v-alert border="left"  outlined color="var(--contrast-color)" class="mt-5 mb-5" elevation="1">
          <v-row>
            <v-col>
              <v-checkbox
                v-model="selected"
                color="var(--contrast-color)"
                label="En cochant cette case et en accédant au site Alveho, vous donnez votre consentement libre et éclairé au traitement et à 
                l’analyse des données qui seront issues de votre navigation pour des objectifs de recherche scientifique."
                id="check-consent"
              ></v-checkbox>

              <v-btn
                depressed
                outlined
                color="var(--contrast-color)"
                :disabled="!selected"
                id="next"
                class="btn-next"
                @click="changeContent"
              >
                Suivant
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <!-- <div v-if="agreement_articles" class="container">
        <h1 class="article-title">
          Sélectionner les catégories qui attirent votre intérêt !
        </h1>
        <h3 class="article-subtitle">
          Vous pouvez choisir 3 catégories maximum.
        </h3>
        
        <div class="card-container">
          <first-session-card :list="listTendance" id="card" @click="carChecking1 = true"></first-session-card>
          <first-session-card :list="listTV"></first-session-card>
          <first-session-card :list="listCulture"></first-session-card>
          <first-session-card :list="listInfo"></first-session-card>
          <first-session-card :list="listSport"></first-session-card>
          <first-session-card :list="listRadio"></first-session-card>
          <first-session-card :list="listClassic21"></first-session-card>
          <first-session-card :list="listMusiq3"></first-session-card>
          <first-session-card :list="listLapremiere"></first-session-card>
          <first-session-card :list="listVivacite"></first-session-card>
          <first-session-card :list="listTipik"></first-session-card>

          <v-btn
            depressed
            color="primary"
            :disabled="!selected"
            id="finish"
            class="btn-next"
            @click="firstSessionCommit"
          >
            Terminer
          </v-btn>
        </div>
      </div> -->
    </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';
import { getAPI } from "@/axios";
import AgreementText from '../components/AgreementText.vue';

// import FirstSessionCard from '../components/FirstSessionCard.vue';

  export default {
    components: {
      //FirstSessionCard,
      AgreementText
    },
    data: () => ({
      selected: false,
      // listArticles: null,
      // listTendance: [],
      // listTV: [],
      // listCulture: [],
      // listInfo: [],
      // listSport: [],
      // listRadio: [],
      // listClassic21: [],
      // listMusiq3: [],
      // listLapremiere: [],
      // listVivacite: [],
      // listTipik: [],
      // cardChecking1: false
    }),
    // computed: {
    //    ...mapGetters([
    //         'getListArticlesFirstSession',
    //         'getFirstSessionCategories'
    //     ])
    // },
    // created: function () {
    //     getAPI.post("get_articles_first")
    //     .then(response =>{
    //       let articles = response.data;

    //       this.listArticles = articles;
    //       this.listTendance = articles["tendance"]
    //       this.listTV = articles["TV"]
    //       this.listCulture = articles["culture"]
    //       this.listInfo = articles["info"]
    //       this.listSport = articles["sport"]
    //       this.listRadio = articles["radio"]
    //       this.listClassic21 = articles["classic21"]
    //       this.listMusiq3 = articles["musiq3"]
    //       this.listLapremiere = articles["lapremiere"]
    //       this.listVivacite = articles["vivacite"]
    //       this.listTipik = articles["tipik"]

    //       this.setListArticlesFirstSession(articles);
    //     }).catch(err => {
    //       console.log(err);
    //     });
    // },
    methods:{
        changeContent(){
          getAPI.post("update_consent")
              .then(setTimeout(()=> {this.$router.push("/home")}, 250))
              .catch(err => {
                  console.log(err);
              });
        },
      //   firstSessionCommit(){
      //     var listCategories = this.getFirstSessionCategories;
      //     var listIdArticles = []
      //     for(let element of listCategories){
      //       var setting;
      //       switch(element){
      //           case "tendance":
      //             setting = "tendance";
      //             listIdArticles.push(this.listTendance[0].id, this.listTendance[1].id, this.listTendance[2].id);
      //             break;
      //           case "tv":
      //             setting = "tv";
      //             listIdArticles.push(this.listTV[0].id, this.listTV[1].id, this.listTV[2].id);
      //             break;
      //           case "culture":
      //             setting = "culture";
      //             listIdArticles.push(this.listCulture[0].id, this.listCulture[1].id, this.listCulture[2].id);
      //             break;
      //           case "info":
      //             setting = "info";
      //             listIdArticles.push(this.listInfo[0].id, this.listInfo[1].id, this.listInfo[2].id);
      //             break;
      //           case "sport":
      //             setting = "sport";
      //             listIdArticles.push(this.listSport[0].id, this.listSport[1].id, this.listSport[2].id);
      //             break;
      //           case "radio":
      //             setting = "radio";
      //             listIdArticles.push(this.listRadio[0].id, this.listRadio[1].id, this.listRadio[2].id);
      //             break;
      //           case "classic21":
      //             setting = "classic21";
      //             listIdArticles.push(this.listClassic21[0].id, this.listClassic21[1].id, this.listClassic21[2].id);
      //             break;
      //           case "musiq3":
      //             setting = "musiq3";
      //             listIdArticles.push(this.listMusiq3[0].id, this.listMusiq3[1].id, this.listMusiq3[2].id);
      //             break;
      //           case "lapremiere":
      //             setting = "lapremiere";
      //             listIdArticles.push(this.listLapremiere[0].id, this.listLapremiere[1].id, this.listLapremiere[2].id);
      //             break;
      //           case "vivacite":
      //             setting = "vivacite";
      //             listIdArticles.push(this.listVivacite[0].id, this.listVivacite[1].id, this.listVivacite[2].id);
      //             break;
      //           case "tipik":
      //             setting = "tipik";
      //             listIdArticles.push(this.listTipik[0].id, this.listTipik[1].id, this.listTipik[2].id);
      //             break;
      //         }
      //       getAPI.post("update_settings", {
      //         params: {
      //               setting_group: "filtering",
      //               setting: setting,
      //               value: true
      //           }
      //         })
      //         .then()
      //         .catch(err => {
      //             console.log(err);
      //         });
      //     }
      //     for(let element of listIdArticles){
      //       getAPI.post("create_reading", {
      //           params: {
      //             article_id: element
      //           }
      //         })
      //         .then(response => {
      //           console.log(response);
      //         })
      //         .catch(err => {
      //           console.log(err);
      //         });
      //     }

      //     getAPI.post("update_first_session")
      //         .then(this.$router.push("/home"))
      //         .catch(err => {
      //           console.log(err);
      //         });
          
      //   },
      //   ...mapActions([
      //   'setListArticlesFirstSession',
      // ])
      
    },
    // watch: {
    //     getListArticlesFirstSession: function (val) {
    //         console.log(val)
    //         this.listArticles = val;
    //         this.listTendance = val["tendance"];
    //         this.listTV = val["tv"];
    //         this.listCulture = val["culture"];
    //         this.listInfo = val["info"];
    //         this.listSport = val["sport"];
    //         this.listRadio = val["radio"];
    //         this.listClassic21 = val["classic21"];
    //         this.listMusiq3 = val["musiq3"];
    //         this.listLapremiere = val["lapremiere"];
    //         this.listVivacite = val["vivacite"];
    //         this.listTipik = val["tipik"];
    //     },
    // }
  }
</script>

<style scoped>
.v-alert{
  background:white!important;
}

>>> .v-label{
  color:black!important;
}

.name{
    font-family:"Exo2";
    font-size:45px;;
    color:teal;
    position:relative;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

img{
  width: 50px;
  margin: auto;
}
.container{
  margin: auto;
  width: 70%;

}
.research p{
  margin-top: 15px;
}
.btn-next{
  float: right;
  margin: 0 10px 10px 0;
}
#finish{
  margin-top: 10px;
}
.article-title, .article-subtitle{
  color: var(--primary-color);
  margin: auto;
  text-align: center;
}


</style>