<template>
  <div class="container">
    <bar-chart :chartData="this.chartData" :style="myStyles">
    </bar-chart>
  </div>
</template>

<script>
import BarChart from '../components/BarChart.vue';
import {mapGetters } from 'vuex';


export default {
    components: { BarChart },

    props: {
        articles: Array,
    },


    computed: {
        ...mapGetters([
            'getArticles',
            'getChartColors'
        ]),
        myStyles () {
            return {
                height: '30vh',
            }
        },
        chartData(){
            // Count the number of articles for each category
            let categories_count = {};
            for (let element of this.articles) {
                if (element.visibility && element.article.category in categories_count) {
                    categories_count[element.article.category] = categories_count[element.article.category] + 1;
                }
                else if (element.visibility) {
                    categories_count[element.article.category] = 1
                }   
            }
            
            var categories_count_array = []
            for (var category in categories_count){
                categories_count_array.push([category, categories_count[category]])
            }
            categories_count_array.sort(function compare(v1,v2){
                return v2[1]-v1[1]
            })

            
            let keys = []
            let values = []
            for (let [category, quantity] of categories_count_array){
                keys.push(category)
                values.push(quantity)
            }

            let backgroundColors = []
            for (const key of keys){
                backgroundColors.push(this.getChartColors[key])
            }

            //Formatting data
            var data = {
                labels:keys,
                datasets: [
                    {
                        data:values,
                        backgroundColor: backgroundColors
                    },
                ]
            }
            return data; 
        },
    },

    

}
</script>
