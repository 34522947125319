<template>
    <v-sheet class="bar" >
        <v-app-bar
        style="border-bottom: 1px solid #d2d2d2 !important; position:fixed; z-index:10"
        flat
        color="white"
        src="../assets/background-nav.png"
        >

        <p id="tour-home-step-start" class="name" @click="toHome">ALVEHO</p>
        <div class="navbar-utils">
            <div id="tour-home-step-profile" class="user-tag" v-if="this.emailFetched">
                <!-- <v-btn
                class="user-tag-button"
                outlined
                rounded
                @click="toProfile"
                >
                    <v-avatar left size="45">
                        <img  class="avatar" :src="'https://www.gravatar.com/avatar/' + getUserEmailHash +'?d=identicon&s=50'">
                    </v-avatar>
                    <p class="ml-4 mr-4 mt-2 mb-2">{{substringEmail}}</p>
                </v-btn> -->
                <v-btn
                class="user-tag-button mr-2"
                outlined
                rounded
                >
                    <v-avatar left size="45" > 
                        <img  class="avatar" :src="'https://www.gravatar.com/avatar/' + getUserEmailHash +'?d=identicon&s=50'">
                    </v-avatar>
                    <p class="ml-4 mr-4 mt-2 mb-2">{{substringEmail}}</p>
                </v-btn>
            </div>
                <!-- <v-btn
                outlined
                rounded
                @click="startTour"
                color="var(--contrast-color)"
                class="ml-4"
                v-if="this.helpVisible"
                >
                    <p class="mt-4">Aide</p>
                </v-btn> -->
                <!-- <div id="tour-home-step-final" class="menu-button">
                    <v-menu class="ml-12" offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            large
                            color="var(--primary-color)"
                            icon
                            rounded
                            >
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(link, id) in links"
                            :key="id"
                            @click="changeView(link)"
                            >
                                <v-list-item-title>{{ link.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div> -->
            </div>
        </v-app-bar>
    </v-sheet>
</template>

<script>
import { getAPI } from "@/axios";
import { mapGetters, mapActions } from 'vuex'


  export default {
    props:{
        current_page:String
    },
    data: () => ({
        select: "Accueil",
        links: [
            {id: 1, name: "Accueil"},
            {id: 2, name: "Mode d'emploi"},
            {id: 3, name: "Profil"},
            {id: 4, name: "Déconnexion"}
        ],

    }),
    computed: {
       ...mapGetters([
            'getUserEmail',
            'getUserEmailHash'
        ]),
        substringEmail(){
            //return this.getUserEmail.substring(0,5);
            return this.getUserEmail.split("@")[0];
        },
        emailFetched(){
            if (this.getUserEmail !== null){
                return true;
            }
            else {
                return false;
            }
        },
        helpVisible(){
            if (this.current_page === "Accueil" | this.current_page ==="Profil"){
                return true
            }
            else {
                return false
            }
        }
    },
    methods:{
        ...mapActions([
            'setUserEmail',
            'setUserEmailHash'
        ]),
        changeView: function(link){
            switch (link.name) {
                case "Accueil":
                    this.$router.push("/home");
                    break;
                case "Mode d'emploi":
                    this.$router.push("/help");
                    break;
                case "Profil":
                    this.$router.push("/Profile");
                    break;

                case "Déconnexion":
                    getAPI.post("logout")
                    .then((response) =>{
                        if (response.status == "200"){
                            this.$router.push("/");  
                        } 
                    })
                    break;
            }
        },
        toProfile: function(){
            this.$router.push("/Profile")
        },
        toHome: function(){
            this.$router.push("/Home")
        },
        startTour: function(){
            if (this.current_page === "Accueil") {
                this.$tours['homeTour'].start()
            }
            else if (this.current_page === "Profil") {
                this.$tours['profileTour'].start()
            }
            
        }
    },
    created: function(){
        getAPI.get("user_email")
        .then(response =>{
          let data = response.data;
          this.setUserEmail(data.email);
          this.setUserEmailHash(data.hash);
        })


    }
    

    
  }
</script>

<style lang="scss">
.avatar{
    border: 1px solid grey;
}

.logo{
    width: auto;
    height: 45px;
}

.name{
    font-family:"Exo2";
    font-size:45px;;
    color:teal;
    position:relative;
    text-align: center;
    width: 100%;
    cursor: pointer;
}

.navbar-utils{
    position:absolute;
    right:0;
}

.user-tag{
    display:inline-block;
}

.user-tag-button{
    color:gray!important;
}
.user-tag-button:hover{
    border:solid var(--contrast-color) 1px!important;
    color: var(--contrast-color)!important;
}
.user-tag-button:hover .avatar{
    border:solid var(--contrast-color) 1px;
}
.menu-button{
    display:inline-block;
    padding-right:55px;
    padding-left:50px;

}


.box select {
    border: none;
    color: #008080;   
    font-size: 20px;
    -webkit-appearance: button;
    appearance: button;
    cursor: pointer;
}


</style>