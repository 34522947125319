export const SET_ARTICLES_STATUS = 'SET_ARTICLES_STATUS';
export const SET_SETTINGS_STATUS = 'SET_SETTINGS_STATUS';
export const REQUEST_FETCH_ARTICLES = 'REQUEST_FETCH_ARTICLES';
export const REQUEST_FETCH_ARTICLES_UNORDERED = 'REQUEST_FETCH_ARTICLES_UNORDERED';
export const REQUEST_FETCH_HISTORY = 'REQUEST_FETCH_HISTORY';
export const SET_MAX_ARTICLES_DISPLAYED = 'SET_ARTICLES_DISPLAYED';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE';
export const SET_OPEN_DIALOG_ARTICLE = 'SET_OPEN_DIALOG_ARTICLE';
export const SET_ARTICLES_ID_ARRAY = 'SET_ARTICLES_ID_ARRAY';
export const SET_USERS = 'SET_USERS';
export const SET_FETCH_SETTINGS = 'SET_FETCH_SETTINGS';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_EMAIL_HASH = 'SET_USER_EMAIL_HASH';
export const SET_FIRST_SESSION_CATEGORIES = 'SET_FIRST_SESSION_CATEGORIES';
export const SET_FIRST_SESSION_CATEGORIES_ALL = 'SET_FIRST_SESSION_CATEGORIES_ALL';
export const SET_LINE_CHART_DATA = 'SET_LINE_CHART_DATA';
export const SET_CHART_COLORS = 'SET_CHART_COLORS';
export const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS';
export const SET_MONITORING_STATUS = 'SET_MONITORING_STATUS';
export const SET_WORD_CLOUD_SEARCH = 'SET_WORD_CLOUD_SEARCH';
export const SET_FILTERING_UPDATE = 'SET_FILTERING_UPDATE';
export const SET_REFRESH_DISABLED = 'SET_REFRESH_DISABLED';