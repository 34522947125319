<template>
    <div class="mt-5 mr-10 ml-10 mb-5">
        <download></download>
    </div>
</template>

<script>
import Download from '../components/Download.vue';

export default {
    components:{
        Download
    }
    
}
</script>

<style scoped>

</style>