<template>
    <div>
            <v-slider
                v-model="similarity"
                label="Similarité"
                max="10"
                min="-10"
                thumb-label
                step="1"
                ticks
                color="var(--primary-color)"
                track-color="var(--scrollbar-color)"
                @change="setRefreshDisabled(false)"
            >
            </v-slider>
            <v-slider
                v-model="actuality"
                label="Actualité"
                max="10"
                min="-10"
                thumb-label
                step="1"
                ticks
                color="var(--primary-color)"
                track-color="var(--scrollbar-color)"
                @change="setRefreshDisabled(false)"
            ></v-slider>
            <v-slider
                v-model="subjectivity"
                label="Subjectivité"
                max="10"
                min="-10"
                thumb-label
                step="1"
                ticks
                color="var(--primary-color)"
                track-color="var(--scrollbar-color)"
                @change="setRefreshDisabled(false)"
            ></v-slider>
            <v-btn @click="updateSettings" block outlined color="var(--primary-color)" :disabled="this.getRefreshDisabled">Actualiser</v-btn>


        <!-- <v-row>
            <v-divider class="mb-2 mt-2"></v-divider>
        </v-row>
        <v-row>
            <p>Priorités de recommandation</p>
        </v-row>

        <v-row>
            <v-col>
                <v-slider
                v-model="sim_priority"
                max="2"
                min="0"
                step="1"
                tick-size="8"
                ticks="always"
                color="var(--scrollbar-color)"
                track-color="var(--scrollbar-color)">
                </v-slider>  
            </v-col>
            <v-col>
                <span
                v-text="simLabel">
                </span>
            </v-col>
            
        </v-row>

        <v-row>
            <v-col>
                <v-slider
                v-model="actu_priority"
                max="2"
                min="0"
                tick-size="8"
                ticks="always"
                color="var(--scrollbar-color)"
                track-color="var(--scrollbar-color)">

                </v-slider>
            </v-col>
            <v-col>
                <span
                v-text="actuLabel">
                </span>
            </v-col>
        </v-row>
        
        <v-row>
            <v-divider class="mb-2 mt-2"></v-divider>
        </v-row>
        <v-row>
            <p>Intensité de chaque facteur</p>
        </v-row>
        <v-row>
            <v-col>
                <v-slider
                v-model="sim_intensity"
                label="Similarité"
                max="10"
                min="1"
                thumb-label
                step="1"
                ticks
                vertical
                color="var(--primary-color)"
                ></v-slider>
            </v-col>
            <v-col>
                <v-slider
                v-model="actu_intensity"
                label="Actualité"
                max="10"
                min="1"
                thumb-label
                step="1"
                ticks
                vertical
                color="var(--primary-color)"
                ></v-slider>                
            </v-col>
        </v-row>

        
        <v-row>
            <v-divider class="mb-5 mt-2"></v-divider>
        </v-row>
        <v-row>
            <v-btn @click="updateSettings" block outlined color="var(--primary-color)" class="mb-2">Appliquer</v-btn>
        </v-row> -->

        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
             color="var(--contrast-color)"
             outlined
            >
            {{ text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="var(--contrast-color)"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>


<script>

import { getAPI } from "@/axios";
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        similarity:0,
        actuality:0,
        subjectivity:0,
        // sim_priority:0,
        // actu_priority:0,
        // sim_intensity: 0,
        // actu_intensity: 0,
        snackbar:"false",
        timeout: 2000,
        text:"Flux d'articles actualisé",
        refresh_disabled:true,

    }),
    
    computed: {
       ...mapGetters([
            'getArticles',
            'getSettings',
            'getRefreshDisabled'
        ]),

    },
    methods: {
        ...mapActions([
            'setListArticles',
            'setArticlesIdArray',
            'setListAllArticles',
            'setSettings',
            'setArticlesStatus',
            'setRefreshDisabled'

        ]),

        updateSettings() {
            let localSettings = this.getSettings
            let settings = []

            let sim_setting={}
            sim_setting.setting_group = "recommender"
            sim_setting.setting = "similarity"
            sim_setting.value = this.similarity
            settings.push(sim_setting)
            localSettings["recommender"]["similarity"] = this.similarity

            let actu_setting={}
            actu_setting.setting_group = "recommender"
            actu_setting.setting = "actuality"
            actu_setting.value = this.actuality
            settings.push(actu_setting)
            localSettings["recommender"]["actuality"] = this.actuality

            let subj_setting={}
            subj_setting.setting_group = "recommender"
            subj_setting.setting = "subjectivity"
            subj_setting.value = this.subjectivity
            settings.push(subj_setting)
            localSettings["recommender"]["subjectivity"] = this.subjectivity

            this.setSettings(localSettings)
            this.setRefreshDisabled(true)
            
            getAPI.post("update_multiple_settings", {
                params: settings
            })
            .then(this.refresh_feed())
            .catch(err => {
                console.log(err);
            });
            

        },

        
        refresh_feed () {
            this.setArticlesStatus(false)
            getAPI.post("refresh_feed", {
                params: {
                    settings_group:"recommender",
                    similarity:this.getSettings["recommender"]["similarity"],
                    actuality:this.getSettings["recommender"]["actuality"],
                    subjectivity:this.getSettings["recommender"]["subjectivity"]
                }
            })
            .then(response =>{
                let articles_array = response.data;
                var list_articles = [];
                for(let article of articles_array){
                    let element = new Object();
                    element["article"] = article;
                    element["visibility"] = this.getSettings["filtering"][article.category];
                    list_articles.push(element);
                }
                this.setListArticles(list_articles);
                this.setArticlesStatus(true)
                var array = []
                for(let element of this.getArticles){
                    if (element.visibility){
                    array.push(element.article.id);
                    }
                }
                this.setArticlesIdArray(array);
                this.snackbar = true;
   
            })
        },
    },
    created: function(){
        var settings = this.getSettings
        if (settings["recommender"]){
            this.similarity = settings["recommender"]["similarity"]
            this.actuality = settings["recommender"]["actuality"]
            this.subjectivity = settings["recommender"]["subjectivity"]

        }
    }
}
</script>

<style scoped>
.v-snackbar{
    justify-content: center;
}

::v-deep .v-slider__thumb {
    background-color:var(--primary-color) !important;
}

::v-deep .v-slider__thumb-label{
        background-color:var(--primary-color) !important;

}

 ::v-deep .v-slider__tick {
      border-radius:5px;
      background-color:var(--scrollbar-color)!important;

  }

  ::v-deep .v-label {
      width: 80px;
  }



</style>