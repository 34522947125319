<template>
    <div class="wrapper">
        <v-container  fluid >
            <v-row align="center" justify="center" class="mt-12">
                <v-col >  
                    <v-card
                    min-height="60vh"
                    width="90vh" 
                    class="mr-10 pl-14 ml-14 main-card" 
                    flat
                    color="transparent"
                    >
                        <p class="name mt-8">ALVEHO</p>

                        <div class="mb-3 ml-10">
                            Alveho est un outil d’éducation aux médias consacré aux algorithmes de recommandation, des programmes informatiques qui hiérarchisent l’information sur de nombreux sites internet. Cet outil, développé dans le cadre du projet de recherche
                            <a href="https://www.algopinion.brussels">Algopinion</a> 
                            , permet de jouer avec un algorithme de recommandation qui organise des articles de presse issus de la RTBF. A travers la manipulation des paramètres du système, Alveho permet d’appréhender de façon interactive les effets de ces technologies sur nos pratiques médiatiques. 
                            Si vous souhaitez utiliser Alveho dans le cadre d'une activité éducative, des ressources pédagogiques sont disponibles sur le site de <a href=https://media-animation.be/>Média Animation</a> .
                        </div>
                        <v-row align="center" justify="center" class="mb-3">
                            <v-btn outlined class="mb-3 mt-5" href="/about" color="var(--contrast-color)" >
                                En savoir plus
                            </v-btn>
                        </v-row>

                    </v-card>
                </v-col>
                <v-col>
                        <v-hover v-slot="{hover}" class="hover-card">
                            <v-card
                            min-height="38vh"
                            width="85vh"
                            class="mb-10 top-card d-flex justify-center align-center"
                            outlined
                            >
                                <v-btn
                                    color="var(--primary-color)"
                                    depressed
                                    large
                                    absolute
                                    class="white--text"
                                >
                                    Découverte
                                    <v-icon class="ml-3">
                                        mdi-open-in-app
                                    </v-icon>
                                </v-btn>
                                    <v-col align="center" justify="center" class="model">
                                        <p class="ma-3">Une version "Découverte" est librement accessible sans inscription préalable. 
                                        Cette version permet de découvrir les fonctionnalités de base d'Alveho.
                                        Toutefois, cette version utilise un nombre limité 
                                        d'articles de presse et n'est donc pas à jour avec l'actualité.
                                        </p>
                                        <v-btn
                                            outlined
                                            color="white">
                                            Vers la version découverte
                                        </v-btn>
                                    </v-col>
                                <v-fade-transition>
                                    <div
                                        v-if="hover"
                                        class="darken-2 v-card--reveal white--text"
                                    >
                                        <v-col align="center" justify="center">
                                            <p class="ma-3">Une version "Découverte" est librement accessible sans inscription préalable. 
                                            Cette version permet de découvrir les fonctionnalités de base d'Alveho.
                                            Toutefois, cette version utilise un nombre limité 
                                            d'articles de presse et n'est donc pas à jour avec l'actualité.
                                            </p>
                                            <v-btn
                                                href="/discovery"
                                                outlined
                                                color="white">
                                                Vers la version découverte
                                            </v-btn>
                                        </v-col>
                                    </div>
                                </v-fade-transition>
                            </v-card>
                        </v-hover>
                    <v-spacer></v-spacer>
                        <v-hover v-slot="{hover}">
                            <v-card
                            min-height="38vh"
                            width="85vh"
                            outlined
                            class="bottom-card d-flex justify-center align-center"
                            >
                                    <v-btn
                                        color="var(--contrast-color)"
                                        depressed
                                        large
                                        absolute
                                        class="white--text"
                                    >
                                        Obtenir des accès
                                        <v-icon class="ml-3"> 
                                            mdi-key-variant
                                        </v-icon>
                                    </v-btn>
                                    <v-col align="center" justify="center" class="model">
                                        <p class="ma-3 ml-10">
                                            Si vous souhaitez organiser une activité d'éducation aux médias avec Alveho,
                                            il est possible d'obtenir des accès privés à la version complète pour chacun de vos participants. Cette version complète permet d'utiliser Alveho
                                            avec des articles de presse publiés le jour-même.
                                        </p>
                                        <v-btn
                                            outlined
                                            color="white"
                                        >
                                            Obtenir des accès
                                        </v-btn>
                                    </v-col>
                                <v-fade-transition>
                                    <div
                                        v-if="hover"
                                        class="d-flex darken-2 v-card-bottom--reveal white--text"
                                    >
                                    <v-col align="center" justify="center">
                                        <p class="ma-3 ml-10">
                                            Si vous souhaitez organiser une activité d'éducation aux médias avec Alveho,
                                            il est possible d'obtenir des accès privés à la version complète pour chacun de vos participants. Cette version complète permet d'utiliser Alveho
                                            avec des articles de presse publiés le jour-même.
                                        </p>
                                        <v-btn
                                            href="https://media-animation.be/ALVEHO-Questionner-le-role-des-algorithmes-de-recommandation.html"
                                            outlined
                                            color="white"
                                        >
                                            Faire une demande
                                        </v-btn>
                                    </v-col>
                                    </div>
                                </v-fade-transition>
                            </v-card>
                        </v-hover>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default ({
    components: {

    },
    data () {
        return {

        }
    }
})
</script>

<style scoped>

.top-card{
    box-shadow: 10px 0px 0 var(--primary-color) inset;
    border: 1px solid var(--primary-color);
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255, 255, 255, 0.5)),url("../assets/background-alveho-discovery2.png");
    background-repeat: no-repeat;
    background-size:cover;
}

.bottom-card{
    box-shadow: -10px 0px 0 var(--contrast-color) inset;
    border: 1px solid var(--contrast-color);
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255, 255, 255, 0.5)),url("../assets/photoEAM.jpeg");
    background-repeat: no-repeat;
    background-size:cover;
}

.wrapper{
    background-image: url("../assets/custom-background-fork.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size:cover;
}

.name{
    font-family:"Exo2";
    font-size:35px;;
    color:teal;
    position:relative;
    text-align: center;
    width: 100%;
    cursor: pointer;
}


.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  height:100%;
  background: var(--primary-color);
  /*url("../assets/background-white-flipped.png"),*/
  background-repeat: no-repeat;
  background-position:right;
  background-size:contain;

}

.v-card-bottom--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  right: 0px;
  width: 100%;
  height:100%;
  background: var(--contrast-color);
  /*url("../assets/background-white.png")*/
  background-repeat: no-repeat;
  background-size:contain;

}

.model {
    position:relative;
    visibility:hidden;
}

.btn-absolute {
    position:absolute;
}




</style>
