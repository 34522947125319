<template>
    <div class="wrapper">
        <p align="center"> Télécharger les logs des utilisateurs:</p>
        <v-row align="center" justify="center">
            <v-btn
            icon
            outlined
            color="var(--primary-color)"
            @click="download"
            class="export-button">
            <v-icon size="80">mdi-download</v-icon>
            </v-btn>
        </v-row>

        <p align="center"> Télécharger le suivi de l'activité des utilisateurs:</p>
        <v-row align="center" justify="center">
            <v-btn
            icon
            outlined
            color="var(--primary-color)"
            @click="download_activity"
            class="export-button">
            <v-icon size="80">mdi-download</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>

<script>
import { getAPI } from "@/axios";

export default {
    methods:{
        download(){
          getAPI.get("logs")
          .then(response =>{
            let data = JSON.parse(JSON.stringify(response.data));
            var fileURL = window.URL.createObjectURL(new Blob([data]));
            var fileLink = document.createElement('a');
            
            fileLink.href = fileURL;
            fileLink.setAttribute('download','file.csv');
            document.body.appendChild(fileLink);

            fileLink.click();
          }).catch(err => {
              console.log(err);
          });
      },
      download_activity(){
          getAPI.get("logs_activity")
          .then(response =>{
            let data = JSON.parse(JSON.stringify(response.data));
            var fileURL = window.URL.createObjectURL(new Blob([data]));
            var fileLink = document.createElement('a');
            
            fileLink.href = fileURL;
            fileLink.setAttribute('download','file.csv');
            document.body.appendChild(fileLink);

            fileLink.click();
          }).catch(err => {
              console.log(err);
          });
      }
    }
}
</script>

<style scoped>
.export-button{
    min-height: 100px;
    min-width: 100px;
    margin:10px 10px 10px 10px;
}
</style>