import axios from 'axios'
import { API_URL } from './constants/index';

const getAPI = axios.create({
    baseURL: API_URL,
    timeout: 12000,
    withCredentials: true,
})

export {
    getAPI
}