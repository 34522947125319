<template>
  <div class="wrapper"  >
    <navbar current_page="Accueil"></navbar>

    <!-- <v-tour  name="homeTour" :steps="steps" :options="tourOptions" :callbacks="tourCallbacks"> -->
      <template slot-scope="tour">
        <transition>
          <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels">
            <template v-if="tour.isLast">
              <div slot="actions">
                <v-btn  small outlined fab color = white class="mr-5" @click="tour.skip"><v-icon>mdi-close</v-icon></v-btn>
                <v-btn  small fab outlined color=white class="mr-1" @click="tour.previousStep" ><v-icon>mdi-arrow-left-bold</v-icon></v-btn>
              </div>
            </template>
            <template v-if="!tour.isLast && !tour.isFirst">
              <div slot="actions">
                <v-btn  small outlined fab color = white class="mr-5" @click="tour.skip"><v-icon>mdi-close</v-icon></v-btn>
                <v-btn  small fab outlined color=white class="mr-1" @click="tour.previousStep" ><v-icon>mdi-arrow-left-bold</v-icon></v-btn>
                <v-btn  small fab outlined color=white class="mr-1" @click="tour.nextStep" ><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
              </div>
            </template>
            <template v-if="tour.isFirst">
              <div slot="actions">
                <v-btn  small outlined fab color = white class="mr-5" @click="tour.skip"><v-icon>mdi-close</v-icon></v-btn>
                <v-btn  small fab outlined color=white class="mr-1" @click="tour.nextStep" ><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    <!-- </v-tour> -->



    <div  class="container maths scroller" >
        <data-panel  class="mt-5" ></data-panel>
    </div>
    <transition name="slide">
      <div v-show="this.getMonitoringStatus"  class="container maths-monitoring scroller" >
          <monitoring-panel  class="mt-5"  ></monitoring-panel>
      </div>
    </transition>
    <transition name="fade-flux">
      <div v-if="!this.getMonitoringStatus" class="container flux scroller">
          <article-card  :articles="this.getArticles" :parent="component_name" v-if="this.getArticlesStatus && !this.getSearchStatus" class="mt-5" ></article-card>
          <article-card :articles="this.getSearchResults" :parent="search" v-if="this.getSearchStatus" class="mt-5"></article-card>
          <article-dialog-box v-if="getOpenDialogArticle"></article-dialog-box>
          <v-layout justify-center>
            <v-progress-circular
            class="mt-16"
            indeterminate
            :size="250"
            :width="15"
            color="var(--primary-color)"
            v-if="!this.getArticlesStatus"
            >
            </v-progress-circular>
          </v-layout>
      </div>
    </transition>
    <div class="container sort scroller">
        <settings-menu  class="mt-5"></settings-menu>
    </div>
  </div>   
</template>

<script>
//import { getAPI } from "@/axios";
import Navbar from '../components/Navbar.vue';
import SettingsMenu from '../components/SettingsMenu.vue';
import ArticleCard from '../components/ArticleCard.vue';
import ArticleDialogBox from '../components/ArticleDialogBox.vue';
import DataPanel from '../components/DataPanel.vue';
import MonitoringPanel from '../components/MonitoringPanel.vue'
import { getAPI } from "@/axios";
import { mapActions, mapGetters } from 'vuex'

  export default {
    name:'home-tour',
    components: {
      Navbar,
      SettingsMenu,
      ArticleCard,
      ArticleDialogBox,
      DataPanel,
      MonitoringPanel,
    },
    data () {
      return {
        data_tab: null,
        //articles_loaded:false,
        articles_unordered_loaded:false,
        component_name:"home",
        search:"search",
        tourOptions:{
          labels:{
            buttonSkip:'Terminer la visite',
            buttonPrevious:'Précédent',
            buttonNext:'Suivant',
            buttonStop:'Terminer'
          }
        },
        tourCallbacks:{
          onSkip: this.setFirstSession,
          onStop: this.setFirstSession
        },
        steps: [
          {
              target: '#tour-home-step-start', 
              content: `Bienvenue sur Alveho! Ce site te permet de consulter des articles publiés par la RTBF tout en profitant d’une multitude d’outils qui viendront enrichir ta navigation et ton suivi quotidien de l’actualité.`
            },
            {
              target: '#tour-home-step-flux', 
              content: `Tu trouveras ici des articles publiés par la RTBF. Ils sont organisés par Ekho, notre algorithme de recommandation. Celui-ci va se baser sur ton historique de navigation\
              pour te suggérer les articles les plus similaires à ce que tu as l'habitude de consulter. Si c'est ta première connexion, les articles sont placés par ordre antéchronologique parce qu'Ekho ne\
              sait pas encore ce que tu as l'habitudes de lire.`,
              params:{
                enableScrolling: false
              }
            },
            {
              target: '#tour-home-step-recommender',
              content: `Ekho va principalement se baser sur ton historique de navigation pour te faire des recommandations. Il va essayer d'identifier les articles les plus similaires à ton historique\
              de navigation. Il va aussi se baser sur la date de publication pour ne pas te proposer des articles trop vieux. Enfin, il va privilégier des articles qu'il considère "objectifs". Toutefois, tu peux ajuster ces trois paramètres selon tes envies.\
              `,
              params: {
                placement:'left',
                enableScrolling: false
              }
            },
            {
              target: '#tour-home-step-filtering',
              content: `Si jamais tu as trop d'articles affichés dans ton flux central, tu peux cacher certaines catégories pour ne garder que ce qui t'intéresse.`,
              params: {
                placement: 'top',
                enableScrolling: false
              }
            },
            {
              target: '#tour-home-step-barchart',  
              content: `Il est parfois difficile de savoir ce qu'il se trouve dans ton flux d'articles. Ce diagramme circulaire peut t'aider en t'affichant la répartition des articles en fonction de leur catégorie\
              .`
            },
            {
              target: '#tour-home-step-barchart-profile',  
              content: `Ce graphique te permet de connaître les types d'articles qui \
              .`
            },
            {
              target: '#tour-home-step-wordcloud', 
              content: `Ce nuage de mot peut aussi te donner une idée des thématiques abordées dans ton flux central. Cela peut t'être utile pour observer les changements de sujets\
              quand tu ajustes tes paramètres de recommandation.`,
              params: {
                placement: 'top',
                enableScrolling: false
              }
            },
            {
              target: '#tour-home-step-comparison', 
              content: `En cliquant sur ce bouton, tu peux faire apparaître un deuxième nuage de mot et un deuxième diagramme circulaire. Ces deux visualisations supplémentaires
              décrivent ce à quoi ressemblerait ton fil d'actualités si les articles étaient simplement organisés selon leur date de publication.\
               `,
               params: {
                placement: 'bottom',
                enableScrolling: false
              }
            },
            {
              target: '#tour-home-step-search',  
              content: `Enfin, si tu souhaites retrouver des articles autrement, tu peux également réaliser une recherche par mots-clés. Tu peux également lancer une recherche\
              en cliquant sur un mot du nuage de mots.`
            },
            {
              target: '#tour-home-step-profile',  
              content: `Les recommandations qui te sont faites sont basées sur ton historique de recommandation. Pour en apprendre plus à ce sujet, clique sur ce bouton pour aller découvrir ton profil.`
            },
            {
              target: '#tour-home-step-final',  
              content: `Si tu veux refaire cette visite, tu peux cliquer sur le bouton "Aide" en haut à droite. Tu peux également trouver plus d'explications via la page "Mode d'emploi"\
              accessible via le menu. `
            },
          ]
        }
      },
    computed: {
       ...mapGetters([
            'getOpenDialogArticle',
            'getArticles',
            'getSearchResults',
            'getSearchStatus',
            'getSettings',
            'getMonitoringStatus',
            'getArticlesStatus'
        ]),
        
        
    },
    methods:{
      ...mapActions([
        'setListArticles',
        'setListArticlesUnordered',
        'setSettings',
        'setSearchStatus',
        'setSearchResults',
        'setArticlesStatus',
        'setSettingsStatus',
        'setHistory'

      ]),
      setFirstSession(currentStep){
        console.log(currentStep)
        getAPI.post("update_first_session")
        .then()
        .catch(err => {
          console.log(err)
        })
      },
      isFirstSession(){
          return this.getSettings.access.first_session
      }

    },
    created: function () {
      this.setSearchStatus(false)
      this.setSearchResults([])
      getAPI.post("settings")
          .then(response =>{
            let results = response;
            let settings = results.data;
            this.setSettings(settings);
            this.setSettingsStatus(true)

            getAPI.get("articles")
              .then(response =>{
                let articles_array = response.data;
                var list_articles = [];
                for(let article of articles_array){
                  let element = new Object();
                  element["article"] = article;
                  //element["visibility"] = settings["filtering"][article.category];
                  element["visibility"] = settings["filtering"][article.category];
                  list_articles.push(element);
                }
                this.setListArticles(list_articles);
                this.setArticlesStatus(true);
                //this.articles_loaded = true;

                // if (this.isFirstSession()){
                //   this.$tours['homeTour'].start()
                // }
                
              }).catch(err => {
                  console.log(err);
              });


              //getAPI.get("articles_unordered")
              //.then(response =>{
              //  let articles_array = response.data;
              //  var list_articles_unordered = [];
              //  for(let article of articles_array){
              //    let element = new Object();
              //    element["article"] = article;
              //    element["visibility"] = true;
              //    list_articles_unordered.push(element);
              //  }
              //  this.setListArticlesUnordered(list_articles_unordered);
              //  this.articles_unordered_loaded = true;

              getAPI.post("get_profile_history",{
                params: this.getSettings["history"]["profile"]
                
              })
                .then( response => {
                  var readings = response.data.articles;
                  var readings_with_visibility= [];
                  for(let reading of readings){
                    let element = new Object();
                    element["article"] = reading;
                    element["visibility"] = true;
                    readings_with_visibility.push(element);
                  }
                  this.setHistory(readings_with_visibility);
                })
                .catch(err => {
                    console.log(err);
              });
                      
             //}).catch(err => {
             //     console.log(err);
             //});


            }).catch(err => {
              console.log(err);
            });
      },
  }
</script>

<style scoped>

  .slide-enter-active{
    transition: all .5s ease;
    transition-delay: 200ms;
  }
  .slide-leave-active {
    transition: all .5s ease;
  }

  .slide-enter, .slide-leave-to {
    transform: translateX(-70px);
    opacity: 0;
  }

  .slide-enter-to, .slide-leave {
    opacity:1;
    transform:translateX(0px);
  }

  .fade-flux-enter-active {
    transition: opacity .5s ease;
    transition-delay: 200ms;
  }
  .fade-flux-leave-active {
    transition: opacity .5s ease;
  }

  .fade-flux-enter, .fade-flux-leave-to {
    opacity: 0;
  }

  .fade-flux-enter-to, .fade-flux-leave {
    opacity:1;
  }



  .v-tour >>> .v-step{
    background:teal!important;
    text-align:justify;
  }

  .wrapper{
    height:100%;    
  }

  .maths{
    width: 30%;
    height: 95vh;
    overflow: scroll;
    position:fixed;
    left:0px;
    top:60px;
  }

  .maths-monitoring{
    width: 30%;
    height: 95vh;
    overflow: scroll;
    position:fixed;
    left:30%;
    top:60px;
  }

  .monitoring{
    width: 30% !important;
  }

  .flux{
    margin-left:30%;
    margin-top:60px;
    width: 50%;
  }
  
  .sort{
    width: 20%;
    overflow: scroll;
    height: 95vh;
    position:fixed;
    right:0px;
    top:60px;
  }


 .scroller{
    scrollbar-color:var(--scrollbar-color) var(--background-color); 
    scrollbar-width: None;
  }


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background:var(--scrollbar-color);
  border: 0px none var(--background-color);
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color);
}
::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-color);
}
::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 0px none var(--background-color);
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: var(--background-color);
}
::-webkit-scrollbar-track:active {
  background: var(--background-color);
}
::-webkit-scrollbar-corner {
  background: transparent;
}


</style>
