<template>
    <v-alert 
    type="info"
    border="left"
    color=var(--contrast-color)
    outlined 
    dismissible
    class="alert">
      {{message}}
  </v-alert>
</template>

<script>

export default {
  props: {
    message: String
  }
}
</script>
