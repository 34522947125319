<template>
    <div>
        <v-progress-circular
            :size="100"
            indeterminate
            color="primary"
            v-if="valid"
            id="progress"
        ></v-progress-circular>
        <alert-box :error="error" v-if="valid === false"></alert-box>
    </div>
</template>

<script>
import { getAPI } from "@/axios";


  export default {
    props: {
        token: {
            type: String
        }
    },
    data: () => ({
        error: null,
        valid: true
    }),
    created: function () {
        getAPI.post("login", {
        params:{
            access_token: this.token
        }})
        .then(response =>{
            let results = JSON.parse(JSON.stringify(response));
            let login_status = JSON.parse(JSON.stringify(results.data));
            if(login_status.first_session){
                this.$router.push("/agreements");
            }
            else{
                this.$router.push("/home");
            }
        }).catch(err => {
            console.log("no"+err);
            this.error = err
            this.valid = false
        });
    }
  }
</script>

<style scoped>
    div{
        margin: auto;
    }
</style>